<template>
  <b-card>
    <div class="mb-2">
      扫描5个运单，10个包裹
    </div>
    <b-table
      :items="items"
      :fields="fields"
      responsive
      class="mb-3"
    />
    <div class="d-flex justify-content-center mt-4">
      <b-button
        variant="relief-primary"
        @click="$router.push({name:'seatransput'})"
      >
        上一步
      </b-button>
      <b-button
        variant="relief-success"
        class="ml-2"
      >
        确定并入库
      </b-button>
    </div>
  </b-card>
</template>

<script>
import {
  BCard, BButton, BTable,
} from 'bootstrap-vue'

export default {
  components: {
    BCard, BButton, BTable,
  },
  data() {
    return {
      items: [{
        shipment_id: 'AU1SPM29993',
      }],
      fields: [{
        key: 'shipment_id',
        label: '运单ID',
      }, {
        key: 'number',
        label: '系统数量',
      }, {
        key: 'scan',
        label: '实际扫描',
      }],
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
